<template>
    <bread-pages>
        <div class="main" v-loading="loading">
            <div class="title">{{ info.name }}</div>
            <div class="sub-title">
                <span>发布时间：{{ info.issuedAt }}</span>
                <span>来源：{{ info.issuedBy }}</span>
            </div>
            <div class="content" v-html="info.content"></div>
        </div>
    </bread-pages>
</template>

<script>
import BreadPage from '../../components/page/BreadPage.vue';
import BreadPages from '../../components/page/BreadPages.vue';
import comEvent from '../../mixins/comEvent';
export default {
    components: { BreadPage, BreadPages },
    mixins: [comEvent],
    data() {
        return {
            loading: false,
            info: {}
        };
    },
    computed: {
        typeName() {
            return this.info.typeSettingId === 325 ? '通知公告' : this.info.typeSettingId === 324 ? '平台动态' : '知产百科';
        },
        indexs() {
            return this.info.typeSettingId === 324 ? 1 : this.info.typeSettingId === 325 ? 2 : 3;
        }
    },
    mounted() {
        this.loading = true;
        this.$http.get('/policyLaw/get/' + this.$route.query.id).then(res => {
            this.info = res;
            setTimeout(() => {
                this.loading = false;
            }, 500);
            this.$nextTick(() => {
                this.$EventBus.$emit('changeTitle', this.typeName);
            });
            this.$EventBus.$emit('changePreTitle', {
                name: '知产咨询动态',
                path: 'newsList?flag=' + this.indexs
            });
            this.$EventBus.$emit('changeTitle', '新闻详情');
            document.title = '新闻详情';
        });
    }
};
</script>

<style lang="less" scoped>
.appContainer{
    background: #FFFFFF !important;
}
.main {
    background-color: #fff;
    padding: 0 150px 60px;
    box-sizing: border-box;
    .min-height(606px);
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    line-height: 34px;
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid @bg;
}
.sub-title {
    .flex();
    justify-content: center;
    font-size: 14px;
    color: #939599;
    line-height: 24px;
    span + span {
        margin-left: 40px;
    }
    padding: 20px 0 40px;
}

.content {
    line-height: 28px !important;
    font-size: 14px !important;
    p {
        font-size: 14px;
        color: #000000;
        line-height: 28px;
    }
}
</style>
